import React, { useContext, useState, useMemo } from "react";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  createPaginationItems,
} from 'components/ui/pagination';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/catalyst/table';
import { Input, InputGroup } from 'components/catalyst/input';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Card, CardHeader, CardContent } from 'components/ui/card';
import { ScrollArea } from 'components/ui/scroll-area';
import ResponsiveModal1 from 'components/catalyst/responsive-modal-1';
import { DataContext } from 'contexts/DataContext';
import DataTableColumnHeader from './PurchasesTableHeader';
import { Badge } from 'components/catalyst/badge';
import { formatValue } from 'utils/formatValue';

export function Purchases() {
  const { customerDailySales } = useContext(DataContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(11); // Set the number of items per page
  const [sortConfig, setSortConfig] = useState({ key: 'invoicedt', direction: 'descending' });
  const [searchInput, setSearchInput] = useState('');

  const sortedItems = useMemo(() => {
    let sortableItems = [...customerDailySales];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        // Custom sorting logic for "status"
        if (sortConfig.key === 'status') {
          const statusOrder = {
            'Credit Memo': 1,
            'Sales Order': 2,
            'Unknown': 3,
          };
          const statusA = a.total_sales > 0 ? 'Sales Order' : a.total_sales < 0 ? 'Credit Memo' : 'Unknown';
          const statusB = b.total_sales > 0 ? 'Sales Order' : b.total_sales < 0 ? 'Credit Memo' : 'Unknown';
          
          if (statusOrder[statusA] < statusOrder[statusB]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (statusOrder[statusA] > statusOrder[statusB]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
        
        // Default sorting logic for other keys
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [customerDailySales, sortConfig]);

  const filteredItems = sortedItems.filter(item => {
    const lowercasedSearchInput = searchInput.toLowerCase();
    return (
      item.agilitysoid.toString().toLowerCase().includes(lowercasedSearchInput) ||
      item.invoicedt.toString().toLowerCase().includes(lowercasedSearchInput) ||
      item.total_sales.toString().toLowerCase().includes(lowercasedSearchInput) ||
      item.items.items.some(subItem =>
        subItem.ITEMDESC.toString().toLowerCase().includes(lowercasedSearchInput) ||
        subItem.SALESAMT.toString().toLowerCase().includes(lowercasedSearchInput)
      )
    );
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const SalesDescription = ({ salesDetails }) => {
    return (
      <div className ="rounded-md border p-4 mt-4 text-left">
      <ScrollArea className="h-[400px] max-w-[650px]">
        {salesDetails?.items.map((item) => (
          <div key={item.ITEMDESC}>
            <div>
              <span className="font-bold">Item:</span> {item.ITEMDESC}
            </div>
            <div>
              <span className="font-bold">Sales Amount:</span> {formatValue(item.SALESAMT)}
            </div>
            <div>
              <span className="font-bold">Quantity:</span> {item.STOCKUOMSHIPQTY}
            </div>
            <br />
          </div>
        ))}
      </ScrollArea>
      </div>
    );
  };

  const getDateRange = (items) => {
    if (items.length === 0) return { earliest: null, latest: null };

    const dates = items.map(item => new Date(item.invoicedt));
    const earliest = new Date(Math.min(...dates));
    const latest = new Date(Math.max(...dates));

    return {
      earliest: earliest.toLocaleDateString(),
      latest: latest.toLocaleDateString(),
    };
  };

  const { earliest, latest } = getDateRange(filteredItems);
  const purchaseText = filteredItems.length === 1 ? 'purchase' : 'purchases';

  return (
    <>
      <Card className="w-full mt-4 h-[938px]">
        <CardHeader>
          <h2 className="text-lg font-semibold">Purchases</h2>
          {filteredItems.length > 0 && (
            <div className="text-sm mt-2">
              <strong>{filteredItems.length}</strong> total {purchaseText} from <strong>{earliest}</strong> to <strong>{latest}</strong>
            </div>
          )}
        </CardHeader>
        <CardContent>
          <div className="overflow-x-auto w-full">
            <InputGroup>
              <MagnifyingGlassIcon />
              <Input
                name="search"
                placeholder="Search&hellip;"
                aria-label="Search"
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
              />
            </InputGroup>
            <Table className="min-w-full mt-4">
              <TableHead>
                <TableRow>
                  <TableHeader className="w-1/4">Agility ID</TableHeader>
                  <TableHeader className="w-1/4">
                    <DataTableColumnHeader
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                      title="Invoice Date"
                      sortKey="invoicedt"
                    />
                  </TableHeader>
                  <TableHeader className="w-1/4">
                    <DataTableColumnHeader
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                      title="Total Sales"
                      sortKey="total_sales"
                    />
                  </TableHeader>
                  <TableHeader className="w-1/4">
                    <DataTableColumnHeader
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                      title="Status"
                      sortKey="status"
                    />
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((item) => (
                  <TableRow key={item.agilitysoid}>
                    <TableCell className="w-1/4">
                      <div className="flex items-center">
                        <div>
                          <div className="text-medium">
                            <ResponsiveModal1
                              trigger={
                                <button className="underline text-left w-full">{item.agilitysoid}</button>
                              }
                              title="Purchase Details"
                              description={<SalesDescription salesDetails={item.items} />}
                            />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="w-1/4 text-left">{item.invoicedt}</TableCell>
                    <TableCell className="w-1/4 text-left">{formatValue(item.total_sales)}</TableCell>
                    <TableCell className="w-1/4 text-left">
                      {item.total_sales > 0 ? (
                        <Badge color="lime">Sales Order</Badge>
                      ) : item.total_sales < 0 ? (
                        <Badge color="red">Credit Memo</Badge>
                      ) : (
                        <span>Unknown</span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Pagination className="mt-6 w-full flex justify-center">
            <PaginationPrevious
              onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
              disabled={currentPage === 1}
            />
            <PaginationContent className="flex justify-center w-full">
              {createPaginationItems(totalPages, currentPage, setCurrentPage)}
            </PaginationContent>
            <PaginationNext
              onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </CardContent>
      </Card>
    </>
  );
}
