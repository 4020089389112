"use client";
import React, { useState, useContext, useEffect } from "react";
import { AlertCircle, Archive, File, Inbox, Search, StickyNoteIcon, Trash2, PlusIcon, CheckCircle, Shield } from "lucide-react";
import { cn } from "utils/utils";
import { Input } from "components/ui/input";
import { Separator } from "components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { TooltipProvider } from "components/ui/tooltip";
import { NoteCreate } from "./note-create";
import { NotesList } from "./notes-list";
import { Nav } from "./components/nav";
import { Button } from "components/ui/button";
import ResponsiveModalNotes from 'components/notes-component/components/responsive-modal-notes'
import { NotesContext } from "contexts/NotesContext";
import { DataContext } from "contexts/DataContext";
import { SearchAccountSwitch } from "components/layout/SearchAccountSwitch";
import { Text } from 'components/catalyst/text'

export function Notes({ accounts, customerId }) {
  const { notes } = useContext(NotesContext);
  const { customerReportData } = useContext(DataContext);

  const [searchInput, setSearchInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Safeguard notes to always be an array
  const safeNotes = Array.isArray(notes) ? notes : [];

  const [selectedLink, setSelectedLink] = useState("All Notes"); // Default selected link
  const [selectedAccount, setSelectedAccount] = useState('');
  const [filteredStatus, setFilteredStatus] = useState(null); 

  useEffect(() => {
    console.log("selectedAccount", selectedAccount);
  }, [selectedAccount]);

  const filteredNotes = safeNotes
    .filter(note => (selectedAccount ? note.custcd === selectedAccount : true))
    .filter(note => {
      if (selectedLink === "All Notes") {
        return note.status === "created" || note.status === "updated";
      } else if (filteredStatus === 'rsm') {
        return Array.isArray(note.tags) && note.tags.length > 0;
      } else if (filteredStatus) {
        return note.status === filteredStatus;
      } else {
        return false;
      }
    })
    .filter(note => {
      const searchTerm = searchInput.toLowerCase();
      return (
        note.header?.toLowerCase().includes(searchTerm) ||
        note.content?.toLowerCase().includes(searchTerm) ||
        note.cust_name?.toLowerCase().includes(searchTerm) ||
        note.creation_date?.toLowerCase().includes(searchTerm)
      );
    });

  const filteredByAccount = safeNotes.filter(note => 
    selectedAccount ? note.custcd === selectedAccount : true
  );

  const draftCount = filteredByAccount.filter(note => note.status === 'draft').length || 0;
  const rsmCount = filteredByAccount.filter(note => Array.isArray(note.tags) && note.tags.length > 0).length || 0;
  const archiveCount = filteredByAccount.filter(note => note.status === 'archived').length || 0;
  const trashCount = filteredByAccount.filter(note => note.status === 'deleted').length || 0;
  const notesCount = filteredByAccount.filter(note => note.status === 'created' || note.status === 'updated').length || 0;

  const handleLinkSelection = (link, status) => {
    setSelectedLink(link);
    setFilteredStatus(status);
  };

  return (
    <TooltipProvider delayDuration={0}>
      <div className="h-full flex flex-col sm:flex-row overflow-hidden sm:space-x-6">
        <div className="sm:flex flex-col sm:w-1/4 pb-4 h-full">
          <div className={cn("flex flex-col items-stretch", "px-0")}>
            <ResponsiveModalNotes
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              trigger={
                <Button variant="outline" className="mb-2 w-full">
                  <PlusIcon className='h-4 w-4 mr-2' />
                  Create Note
                </Button>
              }
              title="Create a New Note"
              description={<NoteCreate customerId={customerId} />}
            />
          </div>
          <Nav
            selectedLink={selectedLink}
            handleLinkSelection={handleLinkSelection} 
            links={[
              { title: "All Notes", label: notesCount, icon: Inbox, status: null },
              { title: "RSM/Admin Notes", label: rsmCount, icon: Shield, status: 'rsm' },
              { title: "Drafts", label: draftCount, icon: File, status: 'draft' },
              { title: "Archive", label: archiveCount, icon: Archive, status: 'archived' },
              { title: "Trash", label: trashCount, icon: Trash2, status: 'deleted' },
            ]}
          />
          <Separator />
        </div>
        <div className="flex flex-col sm:flex-row w-full sm:w-3/4 h-full">
          <div className="flex-1 overflow-y-auto flex flex-col">
            <Tabs defaultValue="all" className="flex-1 flex flex-col">
              <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <h1 className="text-xl font-bold mb-2 sm:mb-0">Notes</h1>
                <TabsList className="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
                  <TabsTrigger value="all" className="text-zinc-600 dark:text-zinc-200">All Notes</TabsTrigger>
                  <TabsTrigger value="bookmark" className="text-zinc-600 dark:text-zinc-200">Bookmarked</TabsTrigger>
                </TabsList>
              </div>
              <div className="bg-background/95 py-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
                <form>
                  <div className="relative">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search"
                      className="pl-8"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <>
              {filteredNotes.length === 0 ? (
                  <Text>There are no notes.</Text>
                ) : (
                  <>
                  <TabsContent value="all" className="flex-1 overflow-y-auto m-0">
                      <NotesList items={filteredNotes} />
                  </TabsContent>
                  <TabsContent value="bookmark" className="flex-1 overflow-y-auto m-0">
                        <NotesList items={filteredNotes ? filteredNotes.filter((item) => item.is_bookmark) : []} />
                  </TabsContent>
                  </>
              )}
              </>
            </Tabs>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
}