import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { getAccessToken } from 'utils/authUtils'

const AuthGuard = ({ children }) => {
  const { testing } = useContext(AppContext);
  if (testing) return children

  const accessToken = getAccessToken(false);
  if (accessToken && (accessToken!="eyJraWQiOiJnRXZwSWFxb1ZjNjhCYjFIZVRPTlRlRXdLYW5VWmNVbmtoZW1CaWJJSGtvIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVyOHV3Mmc3bUFXbXE3RDJwNyIsIm5hbWUiOiJDcmlzdGluIENvbm5lcm5leSIsImVtYWlsIjoiQ3Jpc3Rpbi5Db25uZXJuZXlAc3JzZGlzdHJpYnV0aW9uLmNvbSIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9zcnMub2t0YS5jb20vb2F1dGgyL2F1c3Zvamd2OXh2ekNTRGlYMnA3IiwiYXVkIjoiMG9hdjZ5YWQwN1Jrc2U3NVoycDciLCJpYXQiOjE3Mjg1OTY1NTUsImV4cCI6MTcyODYwMDE1NSwianRpIjoiSUQuZ2lUUXNRSG5MSUQ4eFhoamhJc1lUZVFBM2ZBRHFDR0tCSHpxWXd3bTIxZyIsImFtciI6WyJzbXMiLCJtZmEiLCJtY2EiLCJwd2QiXSwiaWRwIjoiMDBvMTZ6andlY0NGdTdwVWoycDciLCJub25jZSI6InBVeWRFNWVCWTZ3cEpWVklNVzBOQnRGRzl3bDFCUGpueXk3RlVDUHhnZTNSMFhmdTFRNlRmd2tXRVBZOW9RWWUiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJDQzEwNjQ3MkBtbWhmZ2IuY29tIiwiYXV0aF90aW1lIjoxNzI4NTc5ODc5LCJhdF9oYXNoIjoibE9KaWRxQVVXX3JhMFRPbUMyMGtodyJ9.jzRGPqKxRexFG-dhRkVs3mYCCXnPQTilZeS1IZCQpjJS_8Ft3E3U9ejQ-ufB7K5PtxY61GwXh2uQDJOsVX1hAjObW9HHmPYloqM9xGhd0-QTbAmBiM0yeqBQn384ty9aqL7HjNdLb5GQkqZlKhteUJrc4eL7nBgVZmX6wKGR0CqlOwXejPJwwVdb-3Lqj7hOSqgGmHsdQgDrA92wwzB5YrDUYCRXBnmiky16mGovWddtXtYnewjOXp5uB0S3LKT7iYux9s5zDCwNA33Fvp4v0cA6p2MG4KCdXrsoEi0qRcelcOWn2W8ZWlPWZc_VpFzU_85tsbw5drFiQKvavqYGsA")) {
    return children;
  }

  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isLoading = useMemo(() => !authState, [authState]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (!isAuthenticated && !isLoading && !currentUrl.includes('/login/callback')) {
      oktaAuth.signInWithRedirect();
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  if (isLoading || !isAuthenticated) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
