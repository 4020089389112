export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiJnRXZwSWFxb1ZjNjhCYjFIZVRPTlRlRXdLYW5VWmNVbmtoZW1CaWJJSGtvIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVyOHV3Mmc3bUFXbXE3RDJwNyIsIm5hbWUiOiJDcmlzdGluIENvbm5lcm5leSIsImVtYWlsIjoiQ3Jpc3Rpbi5Db25uZXJuZXlAc3JzZGlzdHJpYnV0aW9uLmNvbSIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9zcnMub2t0YS5jb20vb2F1dGgyL2F1c3Zvamd2OXh2ekNTRGlYMnA3IiwiYXVkIjoiMG9hdjZ5YWQwN1Jrc2U3NVoycDciLCJpYXQiOjE3Mjg1OTY1NTUsImV4cCI6MTcyODYwMDE1NSwianRpIjoiSUQuZ2lUUXNRSG5MSUQ4eFhoamhJc1lUZVFBM2ZBRHFDR0tCSHpxWXd3bTIxZyIsImFtciI6WyJzbXMiLCJtZmEiLCJtY2EiLCJwd2QiXSwiaWRwIjoiMDBvMTZ6andlY0NGdTdwVWoycDciLCJub25jZSI6InBVeWRFNWVCWTZ3cEpWVklNVzBOQnRGRzl3bDFCUGpueXk3RlVDUHhnZTNSMFhmdTFRNlRmd2tXRVBZOW9RWWUiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJDQzEwNjQ3MkBtbWhmZ2IuY29tIiwiYXV0aF90aW1lIjoxNzI4NTc5ODc5LCJhdF9oYXNoIjoibE9KaWRxQVVXX3JhMFRPbUMyMGtodyJ9.jzRGPqKxRexFG-dhRkVs3mYCCXnPQTilZeS1IZCQpjJS_8Ft3E3U9ejQ-ufB7K5PtxY61GwXh2uQDJOsVX1hAjObW9HHmPYloqM9xGhd0-QTbAmBiM0yeqBQn384ty9aqL7HjNdLb5GQkqZlKhteUJrc4eL7nBgVZmX6wKGR0CqlOwXejPJwwVdb-3Lqj7hOSqgGmHsdQgDrA92wwzB5YrDUYCRXBnmiky16mGovWddtXtYnewjOXp5uB0S3LKT7iYux9s5zDCwNA33Fvp4v0cA6p2MG4KCdXrsoEi0qRcelcOWn2W8ZWlPWZc_VpFzU_85tsbw5drFiQKvavqYGsA";

    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
