import { Card, CardHeader, CardFooter } from '../../components/ui/card'

export default function WaterCoolerPage() {
  return (
    <div className="border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap pt-4">
        <Card className="w-full">
            <CardHeader>
                <h2 className="text-lg font-semibold">
                Water Cooler feature coming soon
                </h2>
            </CardHeader>
            <CardFooter></CardFooter>
        </Card>
      </div>
    </div>
  )
}