import React, { useContext } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ReferenceLine, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { Badge } from 'components/ui/badge';
import { DataContext } from 'contexts/DataContext';

const formatCurrency = (value) => `$${(value / 1000000).toFixed(1)}M`;

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul className="flex justify-center items-center list-none p-0">
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className="inline-flex items-center mr-4">
          <span style={{ 
            backgroundColor: entry.color, 
            width: '10px', 
            height: '10px', 
            display: 'inline-block', 
            marginRight: '5px' 
          }}></span>
          <span style={{ 
            fontSize: '12px', 
            color: entry.value === 'CalYTD Sales' ? 'black' : undefined 
          }}>
            {entry.value}
          </span>
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white bg-opacity-80 border border-gray-200 p-2 rounded shadow-md">
        <p className="text-[10px]">{`${label} : ${formatCurrency(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
};

export const Rebate = () => {
  const { customerRebate } = useContext(DataContext);
  console.log("customerRebate", customerRebate);

  // Ensure there's valid rebate data
  const rebateData = (customerRebate && customerRebate.length > 0) ? customerRebate[0] : {};
  
  // Check if rebateData has valid sales amounts
  const calytdsalesamt = rebateData.calytdsalesamt || 0; 
  const rebateablesalesamt = rebateData.rebateablesalesamt || 0; 
  const rebatetype = rebateData.rebatetype || 'Unknown'; 

  // Prepare chart data
  const chartData = [
    { name: "Rebateable Sales", value: rebateablesalesamt },
    { name: "CalYTD Sales", value: calytdsalesamt }
  ];

  const gates = (rebateData.crp_json && Object.keys(rebateData.crp_json).length > 0)
    ? Object.values(rebateData.crp_json).map(gate => ({
        name: `Gate ${gate.GATENBR || 'N/A'}`, 
        percentage: (gate.REBATEPCT ? (gate.REBATEPCT * 100).toFixed(1) : 0), 
        purchaseAmt: gate.PURCHASEAMT || 0
      }))
    : [];

  const exclusions = [
    rebateData.commodityexcludeind ? "Commodity" : null,
    rebateData.creditcardexcludeind ? "Credit Card" : null,
    rebateData.digitalexcludeind ? "Digital" : null,
    rebateData.privateexcludeind ? "Private" : null
  ].filter(Boolean); 

  if (calytdsalesamt === 0 && rebateablesalesamt === 0 && gates.length === 0) {
    return 
  }

  return (
    <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-6">
        <h2 className="text-lg font-semibold mb-4">Sales Chart - Type: {rebatetype}</h2>
        <div className="grid grid-cols-3 gap-4 text-sm">
          {gates.map((gate, index) => (
            <div key={index} className="bg-gray-100 p-3 rounded">
              <p className="font-bold mb-1">{gate.name}</p>
              <p>Percentage: {gate.percentage}%</p>
              <p>Amount: {formatCurrency(gate.purchaseAmt)}</p>
            </div>
          ))}
        </div>
        
        <div className="h-[200px] mb-6">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={200}
              data={chartData}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis 
                type="number" 
                tickFormatter={formatCurrency} 
                tickCount={5}
                domain={[0, Math.max(calytdsalesamt, rebateablesalesamt) * 1.2]} 
                fontSize={12}
              />
              <YAxis type="category" dataKey="name" width={70} fontSize={10} />
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                content={<CustomLegend />}
                verticalAlign="top"
                height={36}
              />
              <Bar dataKey="value" name="Sales" barSize={20}>
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={index === 0 ? "#000000" : "#C1E9BB"} />
                ))}
              </Bar>
              {gates.map((gate, index) => (
                <ReferenceLine
                  key={index}
                  x={gate.purchaseAmt}
                  stroke="#6B7280"
                  strokeDasharray="3 3"
                  label={{
                    position: 'top',
                    value: `${formatCurrency(gate.purchaseAmt)}`,
                    fill: '#374151',
                    fontSize: 10,
                    offset: 10,
                  }}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
        
        <div className="flex flex-wrap gap-2 text-sm">
          <strong>Exclusions:</strong>{' '}
          {exclusions.map((exclusion, index) => (
            <Badge
              key={index}
              variant="outline"
            >
              {exclusion}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};
