import React, { useContext } from 'react';
import { Drawer, Card } from '@mui/material';
import { ChatContext } from 'contexts/ChatContext';
import ChatWindow from './chatWindow';
import Input from './components/Input';
import { TopHeading } from './components/Heading';
import ProgressCircle from './components/Progress';
import './styles/Layout.css';
import './styles/Themes.css';

const DesktopChatLayout = ({ open, handleToggle }) => {
  const { typingIndicator } = useContext(ChatContext);

  return (
    <Drawer
      sx={{
        zIndex: 2001,
        '& .MuiDrawer-paper': {
          overflow: 'visible',
          touchAction: 'auto',
          pointerEvents: 'auto',
        },
        pointerEvents: 'auto',
      }}
      anchor="right"
      open={open}
      variant="persistent"
      PaperProps={{
        sx: {
          width: 400,
          pointerEvents: 'auto',
        },
      }} 
    >
      {open && (
        <Card
          sx={{
            border: 'none',
            borderRadius: 0,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiCardHeader-root': { color: 'background.paper' },
          }}
          content={false}
        >
          <div id="pixel-container">
            <div className="vsa-chat-box-container">
              <TopHeading toggle={handleToggle} />
              <div className="vsa-window">
                <ChatWindow />
              </div>
              {typingIndicator ? <ProgressCircle /> : <Input />}
            </div>
          </div>
        </Card>
      )}
    </Drawer>
  );
};

export default DesktopChatLayout;
